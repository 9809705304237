import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img, { GatsbyImageProps } from "gatsby-image";

export type ImageProps = {
  /** name of the file to import. The file must be present in `src/images` */
  filename: string;
} & Omit<GatsbyImageProps, "fluid">;

const Image = ({ filename, ...props }: ImageProps) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) => {
        return n.node.relativePath.includes(filename);
      });
      if (!image) {
        return null;
      }

      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      return <Img fluid={image.node.childImageSharp.fluid} {...props} />;
    }}
  />
);

export default Image;
