import React from "react";

// Components
import Grid, { GridProps } from "@material-ui/core/Grid";
import SectionTitle from "./SectionTitle";

export type SectionT = {
  /** Head of the section */
  title: React.ReactNode;

  /** Content to put in the section */
  content: React.ReactNode;

  /** Props passed to the grid item containing the section */
  gridItemProps?: Partial<GridProps>;

  /** Props passed to the grid container listing the title, content & under */
  gridContainerProps?: Partial<GridProps>;

  /** Optional section under the current section */
  underSection?: SectionT;
};

export type SectionProps = {
  /** Section to render */
  readonly section: SectionT;
} & GridProps;

const Section = ({ section, ...props }: SectionProps): JSX.Element => {
  return (
    <Grid item {...props} {...section.gridItemProps}>
      <Grid
        container
        direction={"column"}
        spacing={3}
        style={{ marginBottom: 0 }}
        {...section.gridContainerProps}
      >
        <Grid item>
          <SectionTitle title={section.title} />
        </Grid>
        <Grid item>{section.content}</Grid>
        {section.underSection ? (
          <Section section={section.underSection} />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Section;
