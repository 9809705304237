/**
 * Layout component that injects a layout around each page and does not re-render
 * between changes.
 *
 * See: https://www.gatsbyjs.com/plugins/gatsby-plugin-layout/
 */

import React from "react";

// Components
import Footer from "../components/Footer/Footer";
import Header from "../components/header";

// Styles
import "./layout.css";
import theme from "../../config/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

const Layout = ({ children }: Props): JSX.Element => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        {children}
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default Layout;
