import PropTypes from "prop-types";
import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Image from "./image";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { NoSsr } from "@material-ui/core";
import { Link } from "gatsby";

const useStyles = makeStyles({
  appBar: {
    color: "#233348",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  drawer: {
    marginLeft: "auto",
  },
  drawerList: {
    width: 250,
  },
  drawerToggle: {
    padding: 20,
  },
  images: {
    marginRight: "auto",
    display: "flex",
    "& > *": {
      padding: `1px 4px`,
    },
  },
  imageLogoIcon: {
    width: 40,
    marginRight: 2,
  },
  imageLogo: {
    width: 68,
  },
});

const navLinks = [
  { displayText: "Accueil", link: "/" },
  { displayText: "Entreprises/Organisations", link: "/enterprise" },
  { displayText: "Particuliers", link: "/individual" },
  { displayText: "Blog", link: "/blog" },
] as const;

const Header = (): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <NoSsr>
        <AppBar
          position="static"
          elevation={0}
          className={classes.appBar}
          color="transparent"
        >
          <Toolbar className={classes.toolbar}>
            <Hidden smUp>
              <IconButton
                className={classes.drawerToggle}
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Link to="/" className={classes.images}>
              {/*<Image
                alt="icon ekee"
                filename="ekee-logo-icon.png"
                className={classes.imageLogoIcon}
              />*/}
              <Image
                alt="logo ekee"
                filename="ekee-logo.png"
                className={classes.imageLogo}
              />
            </Link>
            <Hidden xsDown>
              {navLinks.map((item) => (
                <Button
                  color="inherit"
                  component={Link}
                  key={item.displayText}
                  to={item.link}
                  activeClassName={classes.active}
                >
                  {item.displayText}
                </Button>
              ))}
            </Hidden>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onEscapeKeyDown={handleDrawerClose}
          onBackdropClick={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <List className={classes.drawerList}>
            {navLinks.map((item) => (
              <ListItem
                component={Link}
                button
                key={item.displayText}
                to={item.link}
                activeClassName={classes.active}
              >
                <ListItemText primary={item.displayText} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </NoSsr>
    </React.Fragment>
  );
};

Header.propTypes = {
  companyName: PropTypes.string,
};

Header.defaultProps = {
  companyName: `vHealth`,
};

export default Header;
