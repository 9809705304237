import React from "react";

// Components
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import SubscriptionInput from "./SubscriptionInput";

const Newsletter = (props: TypographyProps<"label">): JSX.Element => (
  <>
    <Typography variant="body1" component="label" {...props}>
      Abonnez-vous à notre Newsletter d'informations
      <SubscriptionInput />
    </Typography>
  </>
);

export default Newsletter;
