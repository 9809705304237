import React from "react";

// Components
import Typography, { TypographyProps } from "@material-ui/core/Typography";

const Address = (props: TypographyProps) => (
  <Typography variant="body1" {...props}>
    eKee
    <br />
    12 Cours de l'intendance
    <br />
    33000 BORDEAUX
    <br />
    France
  </Typography>
);

export default Address;
