import React from "react";

// Components
import Address from "./Address";
import Container from "@material-ui/core/Container";
import EkeeSolution from "./EkeeSolution";
import Links from "./Links";
import Newsletter from "../Newsletter/Newsletter";
import Section, { SectionT } from "./Section";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import { Grid, NoSsr } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "white",
  },
  sections: {
    paddingTop: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const sections: ReadonlyArray<SectionT> = [
  {
    title: "Qui somme-nous ?",
    content: <EkeeSolution />,
    gridItemProps: {
      md: 5,
    },
  },
  {
    title: "Adresse",
    content: <Address />,
    gridItemProps: {
      md: 3,
    },
  },
  {
    title: "Newsletter",
    content: <Newsletter />,
    gridItemProps: {
      md: 4,
    },
    underSection: {
      title: "Liens utiles",
      content: <Links />,
      gridContainerProps: { spacing: 0 },
    },
  },
] as const;

const Footer = (): JSX.Element => {
  const classes = useStyles();

  return (
    <NoSsr>
      <footer className="footer">
        <Container maxWidth="lg" className={classes.container}>
          <Grid
            container
            className={classes.sections}
            direction="row"
            spacing={2}
          >
            {sections.map((s) => (
              <Section key={`footer-${s.title}`} section={s} />
            ))}
          </Grid>
        </Container>
      </footer>
    </NoSsr>
  );
};

export default Footer;
