import React, { HTMLProps, useCallback, useState, useRef } from "react";

// Components
import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";

// Sentry
import { captureMessage, Severity } from "@sentry/browser";

// Styles
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

// Types
import { StandardProps } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
    },
    input: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
      borderRadius: "20px 0px 0px 20px",
      minHeight: "100%",
      background: theme.palette.background.paper,
    },
    button: {
      minHeight: "100%",
      minWidth: "auto",
      borderRadius: "0px 20px 20px 0px",
      background: "#d7e9f961",
    },
  });

export type SubscriptionInputClassKey = "container" | "input" | "button";
export type SubscriptionInputProps = StandardProps<
  {
    InputProps?: InputBaseProps;
  } & HTMLProps<HTMLDivElement>,
  SubscriptionInputClassKey
>;

const SubscriptionInput = ({
  classes,
  InputProps,
}: SubscriptionInputProps): JSX.Element => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const subscribe = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const email = inputRef.current?.value;
      if (!email) {
        return;
      }

      setIsSubscribed(true);
      captureMessage(`New newsletter subscription: ${email}`, {
        extra: { email },
        level: Severity.Info,
      });
    },
    [setIsSubscribed]
  );

  return (
    <form onSubmit={subscribe} className={classes?.container}>
      {isSubscribed ? (
        "Merci, vous serez notifié⸱e !"
      ) : (
        <>
          <InputBase
            inputRef={inputRef}
            className={classes?.input}
            required
            id="newsletter"
            name="email-subscription"
            type="email"
            autoComplete="email"
            placeholder="Adresse email"
            {...InputProps}
          />
          <Button type="submit" className={classes?.button} color="inherit">
            S'abonner
          </Button>
        </>
      )}
    </form>
  );
};

export default withStyles(styles)(SubscriptionInput);
