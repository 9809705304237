import React from "react";

// Components
import Typography from "@material-ui/core/Typography";

const EkeeSolution = (): JSX.Element => (
  <Typography variant="h6" style={{ fontWeight: "bold" }}>
    eKee
    <Typography variant="body1">
      <i>
        La plateforme de gestion de donnée qui révolutionne les échanges,
        connecte les services et automatise la mise à jour grâce aux identités
        numériques.
      </i>
    </Typography>
  </Typography>
);

export default EkeeSolution;
