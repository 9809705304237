import React from "react";

// Components
import Divider, { DividerProps } from "@material-ui/core/Divider";

// Styles
import makeStyles from "@material-ui/styles/makeStyles";

// Types
import { CSSProperties } from "@material-ui/styles";

const useStyles = makeStyles({
  divider: ({ width = 80, color = "black" }: BarProps) => ({
    background: color === "white" ? "white" : "#000",
    height: 2,
    marginTop: 10,
    marginBottom: 10,
    width,
  }),
});

type BarProps = {
  /**
   * Width to apply to the divider
   *
   * @default 80px
   */
  width?: CSSProperties["width"];

  /**
   * Color to apply to the divider
   *
   * @default "black"
   */
  color?: "white" | "black";
} & DividerProps;

const Bar = ({ width, color, ...props }: BarProps) => {
  const classes = useStyles({ width, color });

  return <Divider classes={{ root: classes.divider }} {...props} />;
};

export default Bar;
