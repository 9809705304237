import React from "react";

// Components
import Bar from "../bar";
import Typography, { TypographyProps } from "@material-ui/core/Typography";

export type SectionTitleProps = {
  readonly title: React.ReactNode;
} & Omit<TypographyProps, "title">;

const SectionTitle = ({ title, ...props }: SectionTitleProps): JSX.Element => {
  return (
    <Typography variant="h5" color="inherit" {...props}>
      {title}
      <Bar color="white" />
    </Typography>
  );
};

export default SectionTitle;
