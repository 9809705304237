import { red } from "@material-ui/core/colors";
import { createMuiTheme, Theme } from "@material-ui/core/styles";
import { Palette } from "@material-ui/core/styles/createPalette";

const fontFamily = "Inter, -apple-system, BlinkMacSystemFont, sans-serif";

type ConvexSvg = {
  grey: React.CSSProperties['background'] & React.CSSProperties['fill']
  white: React.CSSProperties['background'] & React.CSSProperties['fill']
}

type Gradients = {
  gradientWhiteGrey: React.CSSProperties["background"],
  gradientGreyWhite: React.CSSProperties["background"],
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    convexSvg: ConvexSvg;
    gradients: Gradients;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    convexSvg?: ConvexSvg
    gradients?: Gradients;
  }
}

const theme = createMuiTheme({
  overrides: {
    MuiListItem: {
      dense: {
        marginBottom: 0,
      },
    },
  },
  convexSvg: {
    grey: "#f5f6fa",
    white: "#fff",
  },
  gradients: {
      gradientWhiteGrey: "linear-gradient(0deg, #f5f6fa 0%, rgba(245,246,255,0.0001) 100%)",
      gradientGreyWhite: "linear-gradient(180deg, #f5f6fa 0%, rgba(245,246,255,0.0001) 100%)",
  },
  typography: {
    fontFamily,
    subtitle1: {
      color: "#7D7987",
    },
    button: {
      fontFamily: "'Nunito', sans-serif",
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#458FF6",
    },
    secondary: {
      main: "#ea582e",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "rgb(38, 42, 62)",
      secondary: "#7D7987",
      disabled: "#CCC",
      hint: "#7D7987",
    },
  },
  shape: {
    borderRadius: 20,
  },
});

export default theme;
