import React from "react";

// Components
import Link from "@material-ui/core/Link";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const links = [
  {
    title: "Linkedin",
    href: "https://www.linkedin.com/company/ekee/?viewAsMember=true",
  },
  { title: "Twitter", href: "https://twitter.com/ekee_io" },
  { title: "Contactez-nous", href: "mailto:contact@ekee.io" },
  { title: "Mentions légales", href: "/legal-notice" },
  //  { title: "Mentions légales", href: "#" },
  //  { title: "Condition générales d'utilisation", href: "#" },
] as const;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,

    "& .MuiLink-root": {
      color: "inherit",
    },
  },
  link: {
    /** Makes links bigger when being on mobile */
    [`@media (pointer: coarse)`]: {
      padding: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5),
    },
  },
}));

const Links = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {links.map((l) => (
        <Typography
          className={classes.link}
          component={Link}
          variant="body1"
          key={l.title}
          href={l.href}
        >
          {">"}&nbsp;{l.title}
        </Typography>
      ))}
    </div>
  );
};

export default Links;
